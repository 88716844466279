export const routes = [
    {
        type: 'enterprise',
        path: '/:enterprise',
        component: () => import('$src/views/enterprise/Enterprise.vue'),
        children: [
            {
                hide: true,
                path: 'myOrganization',
                title: 'My Organization',
                privilege: 'COMPANY_PROFILE_VIEW',
                component: () => import('$src/views/enterprise/organization/Organization.vue')
            },
            {
                hide: true,
                path: 'users',
                title: 'Users',
                privilege: 'USER_VIEW',
                component: () => import('$src/views/enterprise/users/Users.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/workforceFinder/Individual.vue')
                    },
                    {
                        path: ':id/history',
                        component: () => import('$src/views/enterprise/workforceFinder/Individual.vue')
                    },
                    {
                        path: 'client/:id',
                        title: 'Client',
                        component: () => import('$src/views/enterprise/users/ClientUser.vue')
                    }
                ]
            },
            {
                path: 'dashboard',
                title: 'Dashboard',
                icon: ['fas', 'chart-line'],
                component: () => import('$src/views/enterprise/dashboard/Dashboard.vue')
            },
            {
                path: 'workforceFinder',
                title: 'Workforce Finder',
                icon: ['fas', 'users'],
                privilege:'WORKFORCE_FINDER_VIEW',
                component: () => import('$src/views/enterprise/workforceFinder/WorkforceFinder.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/workforceFinder/Individual.vue')
                    },
                    {
                        path: ':id/history',
                        component: () => import('$src/views/enterprise/workforceFinder/Individual.vue')
                    }
                ]
            },
            {
                path: 'clients',
                title: 'Clients',
                icon: ['fas', 'people-roof'],
                privilege:'CLIENT_VIEW',
                component: () => import('$src/views/enterprise/clients/Clients.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/clients/ClientsView.vue')
                    }
                ]
            },
            {
                path: 'projects',
                title: 'Projects',
                icon: ['fas', 'sitemap'],
                privilege:'PROJECT_VIEW',
                component: () => import('$src/views/enterprise/projects/Projects.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/projects/ProjectsView.vue')
                    },
                    {
                        path: ':id/heatPacks',
                        component: () => import('$src/views/enterprise/projects/Project.vue'),
                    },
                    {
                        path: ':id/:t',
                        component: () => import('$src/views/enterprise/projects/Project.vue'),
                    },
                    {
                        path: ':id/:t/:v/heatPacks',
                        component: () => import('$src/views/enterprise/projects/Project.vue'),
                    }
                ]
            },
            {
                path: 'consumables',
                title: 'Consumables',
                icon: ['fas', 'boxes'],
                privilege:'CONSUMABLE_VIEW',
                component: () => import('$src/views/enterprise/consumables/Consumables.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/consumables/ConsumablesView.vue')
                    }
                ]
            },
            {
                path: 'equipment',
                title: 'Equipment',
                icon: ['fas', 'screwdriver-wrench'],
                privilege:'EQUIPMENT_VIEW',
                component: () => import('$src/views/enterprise/equipment/Equipment.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/equipment/EquipmentView.vue')
                    }
                ]
            },
            {
                path: 'documents',
                title: 'Documents',
                icon: ['fas', 'folder'],
                privilege:'DOCUMENT_VIEW',
                component: () => import('$src/views/enterprise/documents/Documents.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/documents/DocumentsView.vue')
                    }
                ]
            },
            {
                path: 'recorders',
                title: 'Recorders',
                icon: ['fas', 'chart-line'],
                privilege: 'RECORDERS',
                component: () => import('$src/views/enterprise/recorders/Recorders.vue'),
                children: [
                    {
                        path: ':id',
                        component: () => import('$src/views/enterprise/recorders/RecorderCycles.vue')
                    },
                    {
                        path: ':id/cycle',
                        component: () => import('$src/views/enterprise/recorders/RecorderCycle.vue')
                    }
                ]
            }
        ]
    },
    {
        // :heattreatment
        type: 'client',
        path: '/',
        component: () => import('$src/views/client/Client.vue'),
        children: [
            {
                title: 'Reports',
                path: 'clientReports',
                icon: ['fas', 'clipboard-check'],
                component: () => import('$src/views/client/reports/Reports.vue')
            }
        ]
    }
]